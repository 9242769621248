import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as LoadingSpinner } from "../../assets/images/loading_spinner.svg";
import Toast, { ToastState } from "../../utils/DesignSystem/Toast";
import * as networkManager from "../../networking/NetworkManager";
import EarnOptInModal from "./EarnOptInModal";
import { kAnalyticsConstants } from "../../constants/AnalyticsConstants";
import * as analyticsManager from "../../managers/AnalyticsManager";

const EarnJoin = (props) => {
	const [isLoading, setLoading] = useState(false);
	const history = useHistory();
	const [toastState, setToastState] = useState<ToastState>({
		status: null,
		title: null,
		message: null,
	});
	const [isShowOptInModal, setIsShowOptInModal] = useState<boolean>(false);

	useEffect(() => {
		const isEarnActive = props.isEarnActive;
		if (isEarnActive) {
			history.push("/earn");
		}
	}, [props.isEarnActive]);

	const onGetLinkClick = () => {
		analyticsManager.recordEvent(kAnalyticsConstants.Earn.getLinkClicked, { newsletter_id: props.selectedNewsletterId });
		setIsShowOptInModal(true);
	};

	const createReferralLink = (audienceProperties: Record<string, string>) => {
		setIsShowOptInModal(false);
		setLoading(true);
		networkManager
			.createReferralLink(props.selectedNewsletterId, audienceProperties)
			.then((referralUrl) => {
				analyticsManager.recordEvent(kAnalyticsConstants.Earn.referralProgramJoined, { newsletter_id: props.selectedNewsletterId, referral_url: referralUrl.referral_url });
				history.push("/earn");
			})
			.catch((error) => {
				analyticsManager.recordEvent(kAnalyticsConstants.Earn.referralProgramJoinError, { newsletter_id: props.selectedNewsletterId, error: error?.message ?? "" });
				setLoading(false);
				setToastState({ status: "error", title: "Something went wrong.", message: "We couldn't create your referral link. Please reach us at team@meco.app." });
			});
	};

	return (
		<div>
			<Toast toastState={toastState} />
			<EarnOptInModal isShow={isShowOptInModal} setIsShow={(showStatus) => setIsShowOptInModal(showStatus)} onAgreeTerms={(audienceProperties) => createReferralLink(audienceProperties)} selectedNewsletterId={props.selectedNewsletterId} />
			<div className="w-full border-[1px] border-card rounded-[10px] mb-5 p-[20px]">
				<div className="flex flex-col sm:flex-row gap-4 sm:gap-5 items-start sm:items-center">
					<div className="flex flex-col gap-2">
						<div className="text-primary-light font-regular font-primary text-fs-heading-small">Start by getting your referral link</div>
						<div className="text-primary-light/50 font-regular font-primary text-fs-body-small">Get your unique tracking link to use when promoting Meco. Once you have your link, we’ll share the current rate we are paying per referral and provide guidance on how best to promote Meco.</div>
					</div>
					<button disabled={isLoading} className="h-[40px] w-[200px] text-primary-light font-medium font-primary text-fs-body focus:outline-none rounded-xl text-sm p-2 px-3 py-2 items-center bg-success-green whitespace-nowrap" onClick={() => onGetLinkClick()}>
						{isLoading ? <LoadingSpinner className="m-auto h-[20px] text-white/20 animate-spin fill-white" /> : <div>Get referral link</div>}
					</button>
				</div>
			</div>
		</div>
	);
};

export default EarnJoin;
