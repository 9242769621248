import { ReactComponent as ExitIcon } from "../../assets/images/exit_icon.svg";

import { FC, useState, useRef, useEffect } from "react";
import { ReactComponent as LoadingSpinner } from "../../assets/images/loading_spinner.svg";
import * as networkManager from "../../networking/NetworkManager";
import Tooltip from "../../utils/Tooltip";
import { Icon } from "@tremor/react";
import { InformationCircleIcon } from "@heroicons/react/24/solid";

const EarnOptInModal = (props) => {
	const [isVisible, setVisible] = useState(false);
	const [isValidEmail, setValidEmail] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isFormValid, setIsFormValid] = useState(false);
	const [referralSignUpModel, setReferralSignUpModel] = useState<Record<string, string>>({
		paypal_email_address: "",
		audience_size: "",
		audience_country_breakdown: "",
	});

	useEffect(() => {
		if (props.isShow) {
			setReferralSignUpModel({
				paypal_email_address: "",
				audience_size: "",
				audience_country_breakdown: "",
			});
			setValidEmail(false);
			setIsLoading(false);
			document.body.style.overflow = "hidden";
			setVisible(true);
		}

		if (!props.isShow) {
			setTimeout(() => {
				document.body.style.overflow = "unset";
				setVisible(false);
			}, 451);
		}
	}, [props.isShow]);

	const inputOnChange = (e: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>) => {
		const fieldValue: string = e.currentTarget.value;
		const fieldId: string = e.currentTarget.id;

		const updatedModel = {
			...referralSignUpModel,
			[fieldId]: fieldValue,
		};

		const isEmailValid = validateEmailAddress(updatedModel.paypal_email_address);
		const areAllFieldsFilled = Object.values(updatedModel).every((value) => value.trim() !== "");

		setReferralSignUpModel(updatedModel);
		setIsFormValid(isEmailValid && areAllFieldsFilled);
	};

	const validateEmailAddress = (emailAddressString): boolean => {
		const isValid = checkEmail(emailAddressString);
		setValidEmail(isValid);
		return isValid;
	};

	function checkEmail(email: string) {
		const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		return emailRegex.test(email) && email != "";
	}

	const onSubmitClick = (e) => {
		e.preventDefault();

		if (!isFormValid || !isValidEmail) {
			setIsLoading(false);
			return;
		}

		networkManager
			.postPaypalEmail(props.selectedNewsletterId, referralSignUpModel.paypal_email_address)
			.then(() => {
				const { paypal_email_address, ...referralSignUpModelWithoutPaypal } = referralSignUpModel;
				props.onAgreeTerms(referralSignUpModelWithoutPaypal);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<div className={`fixed left-0 top-0 bg-black bg-opacity-50 w-screen h-screen flex justify-center items-center transition-all duration-450 ease-in-out z-50 ${props.isShow ? "opacity-1" : "opacity-0"} ${isVisible ? "visible overflow-hidden" : "hidden"}`}>
			<div className="max-h-[90vh] bg-white rounded-[10px] shadow-md p-6 sm:p-8 max-w-[90%] xl:max-w-[760px] flex flex-col gap-4 overflow-scroll">
				<div className="flex flex-row gap-3 justify-between items-center">
					<div className="text-secondary-dark font-bold font-primary text-fs-heading">About the Partner Program</div>
					<button onClick={() => props.setIsShow(false)}>
						<ExitIcon className="h-[26px] opacity-50" />
					</button>
				</div>
				<div className="text-secondary-dark font-regular font-primary text-fs-body">
					<div className="text-success-green font-bold inline-block pr-1">Referrals:</div>We’ll provide you with a unique tracking link and pay you for every new user you drive to Meco from your newsletter or audience.
				</div>
				<div className="text-secondary-dark font-regular font-primary text-fs-body">
					<div className="text-success-green font-bold inline-block pr-1">Earnings:</div>You’ll be able to see what rate Meco is currently paying for each referral on the dashboard tab in the portal.
				</div>
				<div className="text-secondary-dark font-regular font-primary text-fs-body">
					<div className="text-success-green font-bold inline-block pr-1">Duration:</div>The Partner Program is ongoing, and participants can refer new users at any time.
				</div>
				<div className="text-secondary-dark font-regular font-primary text-fs-body">
					Read our{" "}
					<a href="https://www.meco.app/partner-program/terms" target="_blank" className="color: text-success-green hover:text-success-green font-bold">
						Terms & Conditions
					</a>{" "}
					for more details.
				</div>
				<form onSubmit={onSubmitClick} className="flex flex-col gap-4">
					<div className="h-[1px] w-full bg-secondary-dark/20" />
					<div className="flex flex-col gap-3">
						<div className="text-secondary-dark font-bold font-primary text-fs-heading">Payment Process</div>
						<div className="text-secondary-dark font-regular font-primary text-fs-body">
							Earnings from successful referrals are processed on the first week of each month. Please <div className="font-bold inline">add the email address associated with your PayPal account</div> below to be able to receive payments from Meco.
						</div>
						<div>
							<input type="email" disabled={isLoading} id="paypal_email_address" autoFocus={true} value={referralSignUpModel.paypal_email_address} onChange={inputOnChange} className={`bg-black/80 focus:ring-1 focus:border-white focus:ring-white border-1 h-[55px] border-white/30 rounded-[12px] font-regular font-primary placeholder-white/50 w-full max-w-[400px] ${isLoading ? "text-white/50" : "text-white/100"}`} placeholder="Your PayPal email address" />
							{!isValidEmail && (
								<div className="text-brand-red font-regular font-primary text-fs-body-extra-small pl-3 pt-1">
									*If you don't have PayPal, you can{" "}
									<a href="https://www.paypal.com/webapps/mpp/account-selection" target="_blank" className="text-brand-red font-bold">
										create a new account
									</a>{" "}
									in minutes.
								</div>
							)}
						</div>
					</div>
					<div className="h-[1px] w-full bg-secondary-dark/20" />
					<div className="flex flex-col gap-3">
						<div className="text-secondary-dark font-bold font-primary text-fs-heading">Your Audience</div>
						<div className="text-secondary-dark font-regular font-primary text-fs-body">Please provide the information below to help Meco understand your audience.</div>
						<input type="text" disabled={isLoading} id="audience_size" autoFocus={false} value={referralSignUpModel.audience_size} onChange={inputOnChange} className={`bg-black/80 focus:ring-1 focus:border-white focus:ring-white border-1 h-[55px] border-white/30 rounded-[12px] font-regular font-primary placeholder-white/50 w-full max-w-[400px] ${isLoading ? "text-white/50" : "text-white/100"}`} placeholder="Audience Size" />
						<div className="relative w-full">
							<textarea disabled={isLoading} id="audience_country_breakdown" value={referralSignUpModel.audience_country_breakdown} autoFocus={false} onChange={inputOnChange} className={`bg-black/80 focus:ring-1 focus:border-white focus:ring-white border-1 h-[100px] border-white/30 rounded-[12px] font-regular font-primary placeholder-white/50 w-full py-2.5 resize-none pr-[36px] ${isLoading ? "text-white/50" : "text-white/100"}`} placeholder={"Audience Country Breakdown"} />
							<span className="absolute top-2 right-2 flex items-center">
								<Tooltip message={"Provide a breakdown of your audience by location (e.g. US – 50%, India – 20%, etc.)"}>
									<Icon className="cursor-pointer" icon={InformationCircleIcon} color="neutral" variant="simple" size="sm" />
								</Tooltip>
							</span>
						</div>
					</div>
					<div className="h-[1px] w-full bg-secondary-dark/20" />
					<div className="flex flex-row justify-end">
						<button type="submit" disabled={!isFormValid || !isValidEmail || isLoading} className={`text-primary-light font-medium font-primary text-fs-body focus:outline-none rounded-xl text-sm p-2 px-3 py-2 items-center bg-success-green whitespace-nowrap ${isValidEmail && isFormValid ? "opacity-100" : "opacity-50"}`}>
							{isLoading ? <LoadingSpinner className="h-[20px] text-white/20 animate-spin fill-white" /> : <div>Accept terms & continue</div>}
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default EarnOptInModal;
