import React, { useEffect, useState, useRef, FC } from "react";
import { withRouter } from "react-router-dom";
import { Grid, Card, Badge, Flex, Metric, Title, Icon, DateRangePicker, DateRangePickerValue, LineChart, BarChart, Table, TableBody, TableCell, TableRow } from "@tremor/react";
import { UserGroupIcon, CurrencyDollarIcon, ChartBarIcon, ExclamationTriangleIcon, BanknotesIcon, CheckIcon, InformationCircleIcon, PauseIcon, BeakerIcon } from "@heroicons/react/24/solid";
import LoadingView from "../../../../utils/LoadingView";
import Toast, { ToastState } from "../../../../utils/DesignSystem/Toast";
import * as networkManager from "../../../../networking/NetworkManager";
import { PUBGrowDetail, PUBBAffiliateOverviewStatsResponse, OverviewStatType, PUBBReferralOverviewStat, PUBGrowCampaign, NLProfile } from "../../../../models/Models";
import * as analyticsManager from "../../../../managers/AnalyticsManager";
import { kAnalyticsConstants } from "../../../../constants/AnalyticsConstants";
import Tooltip from "../../../../utils/Tooltip";
import { Dropdown, Spinner } from "react-bootstrap";
import { enGB } from "date-fns/locale";
import moment from "moment";
import { CampaignType } from "../../../../models/Enums";

export interface GrowAffiliateDashboardProps {
	publisherProfile?: NLProfile;
	selectedNewsletterId: string;
	isGrowActive?: boolean;
	isGrowAffiliateActive?: boolean;
	loadingProfile: boolean;
	newsletterSwitched?: string;
}

const GrowAffiliateDashboard: FC<GrowAffiliateDashboardProps> = ({ publisherProfile, selectedNewsletterId, isGrowActive, isGrowAffiliateActive, loadingProfile, newsletterSwitched }) => {
	const [toastState, setToastState] = useState<ToastState>({
		status: null,
		title: null,
		message: null,
	});
	const [affiliateDetail, setAffiliateDetail] = useState<PUBGrowDetail | null>(null);
	const [selectedCampaign, setSelectedCampaign] = useState<PUBGrowCampaign | undefined>(undefined);
	const [overviewStatResponse, setOverviewStatResponse] = useState<PUBBAffiliateOverviewStatsResponse | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isMetricLoading, setIsMetricLoading] = useState<boolean>(false);
	const [selectedMonthData, setSelectedMonthData] = useState<DateRangePickerValue>({ selectValue: "t" });

	useEffect(() => {
		if (publisherProfile != undefined && selectedNewsletterId != undefined && isGrowActive && isGrowAffiliateActive) {
			getCampaigns();
		}
	}, [publisherProfile]);

	useEffect(() => {
		if (newsletterSwitched && isGrowActive && isGrowAffiliateActive) {
			getCampaigns();
		}
	}, [newsletterSwitched]);

	useEffect(() => {
		if (selectedCampaign) {
			getCampaignStats(selectedCampaign);
		}
	}, [selectedCampaign]);

	const getCampaigns = () => {
		setIsLoading(true);
		networkManager
			.getGrowDetail(selectedNewsletterId)
			.then((affiliateDetail) => {
				setAffiliateDetail(affiliateDetail);
				setInitialCampaign(affiliateDetail);
			})
			.catch((_) => {
				setIsLoading(false);
				setToastState({ status: "error", title: "Something went wrong.", message: "Please try again later or contact us." });
			});
	};

	const setInitialCampaign = (affiliateDetail: PUBGrowDetail) => {
		var tempUserSelectedCampaign = selectedCampaign;
		if (!tempUserSelectedCampaign) {
			// If no campaign is already selected, use the latest campaign
			const latestCampaign = affiliateDetail.campaigns.reduce((latest, campaign) => {
				if (!campaign.active_from) return latest; // Skip if no date
				if (!latest.active_from) return campaign; // If latest has no date, use the current campaign
				return new Date(campaign.active_from) > new Date(latest.active_from) ? campaign : latest;
			}, affiliateDetail.campaigns[0]);
			tempUserSelectedCampaign = latestCampaign;
		}
		setSelectedCampaign(tempUserSelectedCampaign);
	};

	const getCampaignStats = (campaign: PUBGrowCampaign) => {
		setIsLoading(true);
		const defaultQuery = getDefaultQueryDate(campaign.active_from);
		setSelectedMonthData(defaultQuery);
		networkManager
			.getAffiliateOverviewStats(campaign.id, defaultQuery.from!, defaultQuery.to!)
			.then((affiliateOverviewResponse) => {
				setOverviewStatResponse(affiliateOverviewResponse);
				setIsLoading(false);
			})
			.catch((_) => {
				setIsLoading(false);
				setToastState({ status: "error", title: "Something went wrong.", message: "Please try again later or contact us." });
			});
	};

	const getDefaultQueryDate = (activeFromString?: string): DateRangePickerValue => {
		var momentFromDate = moment().subtract(30, "d");
		var fromDate = momentFromDate.toDate();

		var toDate = new Date();

		if (activeFromString) {
			if (momentFromDate.isBefore(activeFromString)) {
				fromDate = new Date(activeFromString);
			}
		}
		return { from: fromDate, to: toDate, selectValue: "t" };
	};

	const getCardIcon = (statId) => {
		switch (statId) {
			case "new_subscribers":
			case "engagement":
				return UserGroupIcon;
			case "amount_spent":
				return BanknotesIcon;
			case "new_subscribers_graph":
			case "new_engaged_subscribers_graph":
			case "cumulative_subscribers_graph":
			case "cumulative_engaged_subscribers_graph":
				return ChartBarIcon;
			default:
				return CheckIcon;
		}
	};

	const onPickerDateChange = (dateData) => {
		setSelectedMonthData({
			...dateData,
		});

		if (dateData.from === undefined || dateData.to === undefined) {
			return;
		}

		if (dateData) {
			setIsMetricLoading(true);
			networkManager
				.getAffiliateOverviewStats(selectedCampaign!.id, dateData.from, dateData.to)
				.then((affiliateOverviewResponse) => {
					setOverviewStatResponse(affiliateOverviewResponse);
					setIsMetricLoading(false);
					setIsLoading(false);
				})
				.catch((_error) => {
					setToastState({ status: "error", title: "Something went wrong.", message: "We couldn't load your stats. Please try again later." });
				});
		}
	};

	const getEngagementFieldColor = (field: string) => {
		console.log(field);
		if (field.includes("Engaged")) {
			return "text-green-500 indent-[12px] font-medium";
		}

		if (field.includes("Pending")) {
			return "text-primary-light/50 indent-[12px]";
		}

		if (field.includes("Unengaged")) {
			return "text-yellow-500 indent-[12px]";
		}

		return "text-primary-light";
	};

	const renderLoadingSpinner = () => {
		return <Spinner className="loading-spinner spinner-border-sm mt-3 w-[10px]" animation="border" role="status" />;
	};

	const renderMetric = (stat: PUBBReferralOverviewStat) => {
		switch (stat.type) {
			case OverviewStatType.Table:
				return (
					<Card key={stat.id} className="col-span-2 sm:col-span-1">
						<Flex alignItems="start">
							<div className="w-full truncate mt-2">
								<div className="flex flex-row items-center gap-3">
									<Icon icon={getCardIcon(stat.id)} color="green" variant="light" size="sm" />
									<Title>{stat.title}</Title>
								</div>
								{isMetricLoading ? (
									renderLoadingSpinner()
								) : (
									<Table className="w-full mt-3">
										<TableBody>
											{stat.data_points.map((dataPoint, index) => {
												return (
													<TableRow>
														<TableCell className={`font-primary text-primary-light text-fs-body ${getEngagementFieldColor(dataPoint.date)}`}>{dataPoint.date}</TableCell>
														<TableCell>{dataPoint.value}</TableCell>
													</TableRow>
												);
											})}
										</TableBody>
									</Table>
								)}
							</div>
							{stat.status && (
								<div>
									<Badge size="md" color={stat.status_color}>
										{stat.status.toUpperCase()}
									</Badge>
								</div>
							)}
							{stat.hint && (
								<div>
									<Tooltip message={stat.hint}>
										<Icon className="cursor-pointer" icon={InformationCircleIcon} color="slate" variant="simple" size="sm" />
									</Tooltip>
								</div>
							)}
						</Flex>
					</Card>
				);
			case OverviewStatType.Metric:
				return (
					<Card key={stat.id} className="col-span-2 sm:col-span-1">
						<div className="flex flex-col items-start h-full justify-between gap-2">
							<div>
								<div>
									<div className="flex flex-row items-center gap-3 mt-2">
										<Icon icon={getCardIcon(stat.id)} color="green" variant="light" size="sm" />
										<Title>{stat.title}</Title>
									</div>
									<div className="mt-3">{isMetricLoading ? renderLoadingSpinner() : <Metric>{stat.value}</Metric>}</div>
								</div>
								{stat.status && (
									<div>
										<Badge size="md" color={stat.status_color}>
											{stat.status.toUpperCase()}
										</Badge>
									</div>
								)}
								{stat.hint && (
									<div>
										<Tooltip message={stat.hint}>
											<Icon className="cursor-pointer" icon={InformationCircleIcon} color="slate" variant="simple" size="sm" />
										</Tooltip>
									</div>
								)}
							</div>
							{stat.id === "amount_spent" && selectedCampaign?.type == CampaignType.Engaged && <div className="font-primary text-primary-light/50 text-fs-body">With pay per engaged subscriber campaigns, you only pay for readers who actively engage with your newsletter, based on Beehiiv’s data. We evaluate engagement over a seven-day period: if a subscriber unsubscribes or fails to open any emails within that window, they’re classified as unengaged, and you don’t get charged. The best part? If any of these unengaged subscribers begin engaging later, you’ve acquired them at no cost.</div>}
						</div>
					</Card>
				);
			case OverviewStatType.Bar:
				return (
					<Card key={stat.id} className="col-span-2">
						<div className="flex flex-col">
							<div className="flex flex-row justify-between">
								<div className="flex flex-row items-center gap-3 mt-2">
									<Icon icon={getCardIcon(stat.id)} color="green" variant="light" size="sm" />
									<Title>{stat.title}</Title>
								</div>
								{stat.hint && (
									<div>
										<Tooltip message={stat.hint}>
											<Icon className="cursor-pointer" icon={InformationCircleIcon} color="slate" variant="simple" size="sm" />
										</Tooltip>
									</div>
								)}
							</div>
							<div className="flex justify-center mt-5">{isMetricLoading ? renderLoadingSpinner() : <BarChart data={stat.data_points} index="date" categories={["Cumulative New Subscribers"]} colors={["green"]} yAxisWidth={20} startEndOnly={false} showAnimation={true} showLegend={false} showXAxis={true} />}</div>
						</div>
					</Card>
				);
			case OverviewStatType.Line:
				return (
					<Card key={stat.id} className="col-span-2">
						<div className="flex flex-col">
							<div className="flex flex-row justify-between">
								<div className="flex flex-row items-center gap-3 mt-2">
									<Icon icon={getCardIcon(stat.id)} color="green" variant="light" size="sm" />
									<Title>{stat.title}</Title>
								</div>
								{stat.hint && (
									<div>
										<Tooltip message={stat.hint}>
											<Icon className="cursor-pointer" icon={InformationCircleIcon} color="slate" variant="simple" size="sm" />
										</Tooltip>
									</div>
								)}
							</div>
							<div className="flex justify-center mt-5">{isMetricLoading ? renderLoadingSpinner() : <LineChart data={stat.data_points} index="date" categories={["New Subscribers"]} colors={["green"]} yAxisWidth={20} startEndOnly={false} showAnimation={true} showLegend={false} showXAxis={true} />}</div>
						</div>
					</Card>
				);
			case OverviewStatType.Stack:
				return (
					<Card key={stat.id} className="col-span-2">
						<div className="flex flex-col">
							<div className="flex flex-row justify-between">
								<div className="flex flex-row items-center gap-3 mt-2">
									<Icon icon={getCardIcon(stat.id)} color="green" variant="light" size="sm" />
									<Title>{stat.title}</Title>
								</div>
								{stat.hint && (
									<div>
										<Tooltip message={stat.hint}>
											<Icon className="cursor-pointer" icon={InformationCircleIcon} color="slate" variant="simple" size="sm" />
										</Tooltip>
									</div>
								)}
							</div>
							<div className="flex justify-center mt-5">{isMetricLoading ? renderLoadingSpinner() : <BarChart data={stat.data_points} index="date" categories={["Pending", "Unengaged", "Engaged"]} colors={["gray", "yellow", "green"]} yAxisWidth={20} startEndOnly={false} showAnimation={true} showLegend={false} showXAxis={true} stack={true} />}</div>
						</div>
					</Card>
				);
		}
	};

	const getStripePortalAddress = () => {
		if (!publisherProfile) return "";
		return process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL + encodeURI(publisherProfile.email);
	};

	return (
		<div>
			<Toast toastState={toastState} />
			{loadingProfile || !isGrowActive || !isGrowAffiliateActive || isLoading ? (
				<LoadingView isLoading={loadingProfile || isLoading} />
			) : (
				<div className="flex flex-col gap-5">
					{affiliateDetail && !affiliateDetail.cc_on_file && (
						<div className="w-full border-[1px] bg-unsubscribe-red border-card rounded-[10px] p-[20px] font-regular font-primary text-fs-body flex flex-row items-center gap-3">
							<Icon icon={ExclamationTriangleIcon} color="amber" variant="light" size="sm" />
							<div>
								<a href={getStripePortalAddress()} target="_blank" className="text-primary-light">
									Add your card
								</a>{" "}
								information to prevent any disruptions to your campaign.
							</div>
						</div>
					)}
					{selectedCampaign && (
						<div>
							<div className="flex flex-col sm:flex-row justify-between items-left sm:items-center pb-4 gap-3 sm:gap-0">
								<div className="font-medium font-primary text-fs-heading">Campaign</div>
								{affiliateDetail && affiliateDetail.campaigns.length > 1 && (
									<div>
										<Dropdown>
											<Dropdown.Toggle id="option-button" bsPrefix="p-0">
												<div className="font-medium font-primary text-fs-heading-small">
													All campaigns
													<div className="border-solid border-white border-r-2 border-b-2 border-l-0 border-t-0 inline-block p-[3px] rotate-45 ml-[10px] mb-[5px]" />
												</div>
											</Dropdown.Toggle>

											<Dropdown.Menu>
												{(affiliateDetail?.campaigns.length ?? 0) > 1 &&
													affiliateDetail?.campaigns.map((campaign, idx) => (
														<Dropdown.Item eventKey={campaign.id} key={campaign.id} active={selectedCampaign.id === campaign.id} onClick={() => setSelectedCampaign(campaign)}>
															{campaign.id}{campaign.active_from ? "" : " (paused)"}
														</Dropdown.Item>
													))}
											</Dropdown.Menu>
										</Dropdown>
									</div>
								)}
							</div>
							<div className="col-span-1 flex flex-row items-center gap-4">
								{selectedCampaign.active_from ? (
									<div className="flex flex-row items-center gap-3 pr-6 px-3 py-2.5 rounded-lg flex-1 border-[1px] border-card">
										<Icon icon={CheckIcon} color="green" variant="light" size="sm" />
										<div className="font-primary text-primary-light text-fs-heading-small flex flex-col">
											<div className="font-medium text-fs-body-small opacity-50">STATUS</div> Active
										</div>
									</div>
								) : (
									<div className="flex flex-row items-center gap-3 pr-6 px-3 py-2.5 rounded-lg flex-1 border-[1px] border-card">
										<Icon icon={PauseIcon} color="yellow" variant="light" size="sm" />
										<div className="font-primary text-primary-light text-fs-heading-small flex flex-col">
											<div className="font-medium text-fs-body-small opacity-50">STATUS</div> Paused
										</div>
									</div>
								)}
								<div className="flex flex-row items-center gap-3 pr-6 px-3 py-2.5 rounded-lg flex-1 border-[1px] border-card">
									<Icon icon={BeakerIcon} color="neutral" variant="light" size="sm" />
									<div className="font-primary text-primary-light text-fs-heading-small flex flex-col">
										<div className="font-medium text-fs-body-small opacity-50">PRICING MODEL</div>
										<div className="font-primary text-primary-light text-fs-heading-small flex flex-row">
											Pay per&nbsp;
											{selectedCampaign.type == CampaignType.Engaged ? (
												<Tooltip message={"An engaged subscriber is someone who reads at least one newsletter and remains subscribed during their first week."}>
													<div className="underline cursor-pointer">engaged subscriber</div>
												</Tooltip>
											) : (
												"subscriber"
											)}
										</div>
									</div>
								</div>
								{selectedCampaign.active_price && (
									<div className="flex flex-row items-center gap-3 px-3 py-2.5 rounded-lg flex-1 border-[1px] border-card">
										<Icon icon={CurrencyDollarIcon} color="neutral" variant="light" size="sm" />
										<div className="font-primary text-primary-light text-fs-heading-small flex flex-col">
											<div className="font-medium text-fs-body-small opacity-50">CPA</div> {selectedCampaign?.active_price}
										</div>
									</div>
								)}
							</div>
						</div>
					)}
					<div>
						<div className="flex flex-col sm:flex-row justify-between items-left sm:items-center pb-4 gap-3 sm:gap-0">
							<div className="font-medium font-primary text-fs-heading">Performance</div>
							<div>
								<DateRangePicker value={selectedMonthData} locale={enGB} weekStartsOn={1} minDate={selectedCampaign?.active_from ? new Date(selectedCampaign?.active_from) : new Date()} maxDate={new Date()} enableClear={false} onValueChange={(dateData) => onPickerDateChange(dateData)} className="max-w-full !border-0" />
							</div>
						</div>
						<div className="grid gap-6 grid-cols sm:grid-cols-2">
							{overviewStatResponse &&
								overviewStatResponse.overview_stats
									.sort((a, b) => a.sort_index - b.sort_index)
									.map((stat) => {
										return renderMetric(stat);
									})}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default withRouter(GrowAffiliateDashboard);
